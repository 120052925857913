if (window.carouselInitialized === undefined) {
  window.carouselInitialized = true

  const observer = new IntersectionObserver(async (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        (await import('../../carousel')).default(entry.target)
        observer.unobserve(entry.target)
      }
    }
  })

  const elements = Array.from(document.getElementsByClassName('js-carousel-container'))

  for (const element of elements) {
    observer.observe(element)
  }
}
